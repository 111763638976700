<template>
    <div class="page-recieve">
        <headeBar title="奖品领取确认" left-arrow @click-left="newAppBack" />
        <div class="address none" @click="showAddress">
            <van-icon name="location" />
            <div class="pub_onefull ct">
                <div v-if="hasAddress" class="pd">
                    <div class="line1">{{ addressOBJ.areaList[0].name+addressOBJ.areaList[1].name+addressOBJ.areaList[2].name }}</div>
                    <div class="line2">{{ addressOBJ.detail }}</div>
                    <div class="line3">{{ addressOBJ.name }} <span class="phone">{{ addressOBJ.phone }}</span> </div>
                </div>
                <div class="tips" v-else>
                    请填写地址，用于物品配送
                </div>
            </div>
            <van-icon name="arrow" />
        </div>
        <div class="jg"></div>
        <div class="records-model">
            <div class="records-img">
                <img :src="rewardObj.rewardImg" alt="" />
            </div>
            <div class="records-info">
                <div class="records-name">{{ rewardObj.rewardName }}</div>
                <div class="records-tips">确认发货后将进行短信告知</div>
            </div>
        </div>
        <div class="fb">
            <div class="t1" v-if="!$route.query.venueId">领取当前的奖品后，将无法再领取同等级的奖品哦</div>
            <div class="sub" :class="{ gray: !hasAddress }" @click="sure">确认领取</div>
            <div class="iphonex-pb"></div>
        </div>
        <AddAddress @setAddress="setAddress" ref="refAddAddress" />
    </div>
</template>
<script>
import { newAppBack, initBack } from '@/lib/appMethod';
import userMixin from '@/mixin/userMixin';
import headeBar from '@/components/app/headBar';
import AddAddress from './address.vue';
let inviteRewardsType,inviteType,venueId;
export default {
    components: {
        headeBar,
        AddAddress
    },
    mixins: [userMixin],
    data() {
        return {
            hasAddress: false,
            addressOBJ: {},
            rewardObj: {}
        }
    },
    methods: {
        newAppBack,
        showAddress() {
            this.$refs.refAddAddress.show = true;
        },
        sure() {
            this.$toast.loading({
                duration: 0,
                forbidClick: true,
                loadingType: 'spinner',
            })
            const ps = {
                inviteRewardsType,
                inviteType,
                venueId,
                address: {
                    addressDetail: this.addressOBJ.detail,
                    city: this.addressOBJ.areaList[1].name,
                    district: this.addressOBJ.areaList[2].name,
                    name: this.addressOBJ.name,
                    phone: this.addressOBJ.phone,
                    province: this.addressOBJ.areaList[0].name
                },
                rewardId: this.rewardObj.rewardId,
                userId: this.userId,
                token: this.token
            }

            this.$axios
                .post(this.baseURLApp + "/schedule/invitation/manualReceive", ps)
                .then((res) => {
                    this.$toast.clear();
                    this.$toast('领取成功');
                    this.$router.go(-1);
                }).catch(() => {
                    this.$toast.clear()
                });

        },
        setAddress(obj) {
            console.log(obj);
            this.addressOBJ = obj;
            this.hasAddress = true;
        },
    },
    created() {
        const rewardId = this.$route.query.rewardId;
        inviteRewardsType = this.$route.query.inviteRewardsType;
        inviteType = this.$route.query.inviteType;
        venueId = this.$route.query.venueId;
        this.$axios
            .post(this.baseURLApp + "/schedule/invitation/getRewardDetail", {
                inviteRewardsType,
                inviteType,
                rewardId,
                userId: this.userId,
                token: this.token,
                venueId
            })
            .then((res) => {
                this.rewardObj = res.data;
                const d=res.data.addressVO;
                if(d&&d.phone){
                    this.setAddress({name:d.name,areaList:[{name:d.province},{name:d.city},{name:d.district}],phone:d.phone,detail:d.addressDetail})
                }
            })
    }
}
</script>
<style lang="less" scoped>
.page-recieve {
    position: absolute;
    height: 100vh;
    top: 0;
    // bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    div {
        box-sizing: border-box;
    }

    img {
        vertical-align: top;
        width: 100%;
    }

    .address {
        display: flex;
        align-items: center;
        padding: 0 32px;
        border-top: 1px solid #EEEEEE;
        min-height: 144px;
        position: relative;

        &::after {
            content: '';
            height: 4px;
            background-image: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/border.png);
            background-size: cover;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
        }

        .ct {
            margin: 0 20px;
        }

        .tips {
            font-size: 30px;
            font-weight: bold;
        }

        &.none {
            color: #A65123;
        }

        .pd {
            padding: 40px 0 56px;
        }

        .line1 {
            font-size: 24px;
            color: #666;
        }

        .line2 {
            color: #242831;
            font-size: 30px;
            font-weight: bold;
            margin: 20px 0;
        }

        .line3 {
            color: #242831;
            font-size: 26px;

            .phone {
                color: #666;
            }
        }
    }

    .jg {
        background: #F5F5F5;
        height: 16px;
    }

    .records-model {
        display: flex;
        padding: 48px 32px 0;

        .records-img {
            width: 160px;
            height: 160px;
            margin-right: 40px;
        }

        .records-name {
            font-size: 28px;
            font-weight: bold;
            color: #242831;
            margin-bottom: 20px;
        }

        .records-tips {
            font-size: 22px;
            color: #666;
            line-height: 34px;
        }
    }

    .fb {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 32px;

        .t1 {
            color: #242831;
            font-size: 24px;
            text-align: center;
            margin-bottom: 40px;
        }

        .sub {
            border-radius: 8px;
            line-height: 96px;
            color: #fff;
            font-size: 32px;
            background: linear-gradient(90deg, #FF7F37 0%, #FF3672 100%);
            text-align: center;
        }

        .gray {
            opacity: 0.5;
            pointer-events: none;
        }
    }
}
</style>
